.marquee-container {
  overflow: hidden !important;
  position: relative !important;
}

.marquee-container .marquee {
  position: absolute !important;
  width: 100% !important;
}

.marquee-container::before,
.marquee-container::after {
  content: ' ' !important;
  position: absolute !important;
  z-index: 1 !important;
}

.marquee-container:not(.horizontal)::before,
.marquee-container:not(.horizontal)::after {
  height: 10% !important;
  width: 100% !important;
}

.marquee-container:not(.horizontal)::before {
  background: rgb(0, 0, 0) !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.6) 80%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  top: 0 !important;
}

.marquee-container:not(.horizontal)::after {
  background: rgb(0, 0, 0) !important;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.6) 80%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  bottom: 0 !important;
}

.marquee-container.horizontal::before,
.marquee-container.horizontal::after {
  height: 100% !important;
  width: 5% !important;
}

.marquee-container.horizontal::before {
  background: rgb(0, 0, 0)  !important;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.6) 80%,
    rgba(0, 0, 0, 0) 100%
  )  !important;
  left: 0  !important;
}

.marquee-container.horizontal::after {
  background: rgb(0, 0, 0)  !important;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.6) 80%,
    rgba(0, 0, 0, 0) 100%
  )  !important;
  right: 0  !important;
}

.marquee-container.horizontal .marquee {
  align-items: center !important;
  display: flex !important;
  height: 100% !important;
  justify-content: center !important;
  white-space: nowrap !important;
}
